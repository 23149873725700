import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static outlets = [ "live-view" ]

  static targets = [
    "escalationFilter",
    "alertFilter",
    "bedFilter",
    "departmentFilter",
    "filterable",
    "escalationCount",
    "alertCount",
    "bedCount",
    "escalatedResident",
    "inBedResident",
    "outOfBedResident",
    "alertResident"
  ]

  static values = {
    filteredEscalations: Boolean,
    filteredAlerts: Boolean,
    filteredBeds: Boolean,
    renewPath: String,
  }

  connect() {
    console.debug("connected to dashboard")

    this._updateFilters()
    this._hideSidebar()
    window.dashboardRenewCall = async () => {
      const response = await get(this.renewPathValue, { responseKind: "turbo-stream" })
      if (response.redirected) {
        window.location = new URL(response.response.url.toString(), document.baseURI)
      }
    }
    this.renewInterval = setInterval(window.dashboardRenewCall, 15000)
  }

  disconnect() {
    clearInterval(this.renewInterval)
  }

  alertResidentTargetConnected(_element) {
    this._updateFilters()
  }

  alertResidentTargetDisconnected(_element) {
    this._updateFilters()
  }

  escalatedResidentTargetConnected(_element) {
    this._updateFilters()
  }

  escalatedResidentTargetDisconnected(_element) {
    this._updateFilters()
  }

  inBedResidentTargetConnected(_element) {
    this._updateFilters()
  }

  inBedResidentTargetDisconnected(_element) {
    this._updateFilters()
  }

  outOfBedResidentTargetConnected(_element) {
    this._updateFilters()
  }

  _updateFilters(){
    this._setAlertFilterCount()
    this._setEscalationFilterCount()
    this._setBedOutFilterCount()

    this._updateFilteredResidentCards()
  }

  _setAlertFilterCount() {
    this.alertCountTarget.textContent = this.alertResidentTargets.length
  }

  _setEscalationFilterCount() {
    this.escalationCountTarget.textContent = this.escalatedResidentTargets.length
  }

  _setBedOutFilterCount() {
    this.bedCountTarget.textContent = this._outOfBedTargets().length
  }

  _outOfBedTargets() {
    return this.filterableTargets.filter(f => this.outOfBedResidentTargets.includes(f))
  }

  filterEscalations() {
    this.filteredEscalationsValue = !this.filteredEscalationsValue
    this.escalationFilterTarget.classList.toggle("active", this.filteredEscalationsValue)

    this._updateFilteredResidentCards()
    this._hideDetailPanel()
  }

  filterAlerts() {
    this.filteredAlertsValue = !this.filteredAlertsValue
    this.alertFilterTarget.classList.toggle("active", this.filteredAlertsValue)

    this._updateFilteredResidentCards()
    this._hideDetailPanel()
  }

  filterBeds() {
    this.filteredBedsValue = !this.filteredBedsValue
    this.bedFilterTarget.classList.toggle("active", this.filteredBedsValue)

    this._updateFilteredResidentCards()
    this._hideDetailPanel()
  }

  _updateFilteredResidentCards(){
    var toHide = []
    if (this.filteredBedsValue) {
      toHide.push(...this.inBedResidentTargets)
    }
    if (this.filteredAlertsValue) {
      toHide.push(...this._withoutAlertTargets())
    }
    if (this.filteredEscalationsValue) {
      toHide.push(...this._notEscalatedResidentTargets())
    }

    this._setHidden(toHide, true)

    var toDisplay = this.filterableTargets.filter(f => ! toHide.includes(f))
    this._setHidden(toDisplay, false)
  }

  _notEscalatedResidentTargets() {
    return this.filterableTargets.filter(f => ! this.escalatedResidentTargets.includes(f))
  }

  _withoutAlertTargets() {
    return this.filterableTargets.filter(f => ! this.alertResidentTargets.includes(f))
  }

  _setHidden(elements, hidden) {
    elements.forEach((element, _index) => {
      element.hidden = hidden
    })
  }

  _hideDetailPanel() {
    const panel = document.getElementById("detail-site-panel")
    if (panel) {
      panel.hidden = true
      this._stopLiveView()
    }
  }

  // need to stop the live view as it continues to poll when hidden
  _stopLiveView() {
    this.liveViewOutlets.forEach(liveView =>
      liveView.stopRefreshingPoses()
    )
  }

  _hideSidebar() {
    $(".sidebar-o").removeClass("sidebar-o")
  }


  filterDepartments() {
    this.setAttributes()
    this._hideDetailPanel()
  }

  setAttributes() {
    this.filterableTargets.forEach((el, _i) => {
      const filterableKeyDepartment = el.getAttribute("data-filter-department")
      if (this.departmentFilterTarget.value && filterableKeyDepartment != this.departmentFilterTarget.value) {
        $(el).hide(300)
      }
      else {
        $(el).show(300)
      }
    })
  }
}
