import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    deleteAtUtc: String
  };

  connect() {
    if (!this.deleteAtUtcValue) { return; }

    this.deleteAtDate = new Date(this.deleteAtUtcValue);
    this.setupTimer();
  }

  setupTimer() {
    if (Date.now() < this.deleteAtDate) {
      console.log("timer setup");
      this.timer = setInterval(() => {
        this.checkExpire();
      }, 1000);
    }
  }

  checkExpire() {
    if (Date.now() < this.deleteAtDate) { return; }

    this.stopTimer();
    this.element.remove();
  }

  stopTimer() {
    if (!this.timer) { return; }

    clearInterval(this.timer);
  }

  disconnect() {
    this.stopTimer();
  }
}
