import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "tab" ]
  static classes = [ "active" ]

  clicked({ params: { clickedTabIndex } }){
    this.tabTargets.forEach((tab, index) => {
      if (index == clickedTabIndex) {
        tab.classList.add(this.activeClass)
      } else {
        tab.classList.remove(this.activeClass)
      }
    })
  }
}
